import { Icon } from "@iconify/react/dist/iconify.js";
import { Modal, Flex, Button, Text } from "@mantine/core";
import { ReactNode } from "react";

export type ModalConfirmDeleteProps = {
  isOpen: boolean;
  illustration?: ReactNode;
  title: string;
  description: string | ReactNode;
  confirmText?: string;
  onConfirm: () => void;
  cancelText?: string;
  onClose: () => void;
  loading?: boolean;
};

function ModalConfirmDelete({
  isOpen,
  title,
  description,
  confirmText = "Hapus",
  onConfirm,
  cancelText = "Batal",
  onClose,
  loading = false,
  illustration,
}: ModalConfirmDeleteProps) {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      withCloseButton={false}
      size="sm"
      radius="md"
      centered
    >
      <Flex direction="column" justify="center" align="center">
        {illustration ?? (
          <Icon
            icon="tabler:circle-x-filled"
            className="w-16 h-16 font-light text-red-500"
          />
        )}
        <Text fw={500} mt="lg" size="md">
          {title}
        </Text>
        <Text fw={300} mt="xs" size="sm" maw={300} className="text-center">
          {description}
        </Text>
        <Flex
          direction="row"
          w="100%"
          justify="space-between"
          gap="md"
          align="center"
          mt="lg"
        >
          <Button
            size="md"
            variant="filled"
            color="red"
            aria-label="Delete"
            fullWidth
            onClick={onConfirm}
            loading={loading}
            leftSection={
              <Icon icon="tabler:trash" className="w-4 h-4 font-light" />
            }
          >
            <Text fw={300}>{confirmText}</Text>
          </Button>

          <Button
            size="md"
            variant="default"
            aria-label="Cancel"
            fullWidth
            onClick={onClose}
            leftSection={
              <Icon icon="tabler:x" className="w-4 h-4 font-light" />
            }
          >
            <Text fw={300}>{cancelText}</Text>
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ModalConfirmDelete;
